.awssld__wrapper
{
    max-height: 80vh;
}

.awssld__content > img
{
   
    object-fit: contain !important;
}

.awssld__content
{
    background-color: #e0e0e0 !important;
}