.splashWrapper
{
    height: 90vh;   
}

.splash_logo
{
    height: 22rem;
   

}

.banner
{
    color:white;
}