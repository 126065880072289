.opaque_bg
{
    background-color: #e30613 !important;
}

.transp_bg
{
    background-color: transparent !important;
    box-shadow: unset !important;
    
}

.logo
{
    max-width: 9.1em;
}